@use "./variables";

.laas-page-landing {
    width: 100%;
    padding: 15vh 7vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--primary-color);

    .header-txt {
        color: white;
        text-transform: uppercase;
        font-size: 44px;
        text-align: center;
    }

    .header-sub-txt {
        color: white;
        font-size: 21px;
        text-align: center;
        margin-bottom: 50px;
        line-height: 1.5;
    }

    .laas-tabs {
        gap: 2rem;
        .ant-tabs-nav {
            width: 35%;

            .ant-tabs-tab {
                padding: 20px 20px 20px 20px;
                font-size: 18px;
                color: white;
                background-color: #ffffff87;
                border-radius: 12px;

                .ant-tabs-tab-btn {
                    width: 100%;
                    .custom-tab-label {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        min-width: 100px;
                        gap: 18px;

                        .tab-text {
                            text-align: center;
                            font-weight: bold;
                            text-transform: uppercase;
                        }

                        .tab-arrow {
                            opacity: 0;
                            transition: opacity 0.3s ease;
                            margin-left: auto;

                            &.visible {
                                opacity: 1;
                            }
                        }
                    }
                }

                &.ant-tabs-tab-active {
                    background: white;
                    // border: thin solid white;

                    * {
                        color: var(--primary-color);
                    }
                }

                &:hover {
                    color: #ffffffab;
                }
            }

            .ant-tabs-ink-bar {
                &.ant-tabs-ink-bar-animated {
                    width: 0;
                }
            }
        }

        .ant-tabs-content-holder {
            background: var(--primary-color);
            border: none;

            .tab-content {
                opacity: 0;
                transform: translateY(10px);
                transition: opacity 0.5s ease, transform 0.5s ease;

                .view-more-btn {
                    margin-top: 1rem;
                }
            }

            .ant-tabs-tabpane-active .tab-content {
                opacity: 1;
                transform: translateY(0);
            }

            p {
                color: white;
                font-size: 18px;
            }

            h3 {
                color: white;
                font-size: 32px;
                font-family: "Inter";
                font-weight: 500;
            }
        }
    }
}

.laas-page-titles {
    margin: 0 auto;
    padding: 100px 20px;
    background-color: #faf9f9;

    h4 {
        font-size: 22px;
        font-family: "Inter";
        font-weight: 500;
    }

    .text-center {
        text-align: center;
    }

    ul,
    ol {
        li {
            font-size: 18px;
        }
    }

    &:nth-child(odd) {
        background-color: #f0f2f5;
    }

    &.contain {
        padding: 100px calc((100% - 1200px) / 2);

        p {
            font-size: 18px;
        }
    }

    & > h2 {
        font-size: 40px;
        font-family: "Inter";
        font-weight: 500;
    }
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 300px;
        gap: 100px;
        margin: 0 auto;
    }
}

.laas-page-intro-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
}

.laas-page-intro-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.laas-page-titles img {
    width: 100%;
    margin: 36px 0 0;
}

// #how-it-works.laas-page-titles {
//     margin: 0 auto;
//     text-align: center;
// }

@media screen and (min-width: 1440px) {
    .laas-page-landing {
        padding: 10vh calc((100% - 1000px) / 2);
    }
}
