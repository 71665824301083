@import "~antd/dist/antd.css";

/* CUVids green: #5bd1d7, darker green: #336d70 */

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: white;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.module-titles {
    display: flex;
    justify-content: center;
    font-weight: bold;
    padding-top: 0.5%;
}

.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
}

.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

[data-theme="dark"] .site-layout-content {
    background: #141414;
}

.rbc-time-content {
    border-top: none;
    padding-top: 0px;
}
.rbc-time-header.rbc-header-overflowing > .rbc-row:nth-of-type(2) {
    display: none;
}

.dashboard-module-list > .ant-card-body {
    padding: 0px;
}

.dashboard-module-list > .ant-card-head {
    background: #182a3f15;
}

.certificate-section {
    background: white;
    margin-bottom: 8px;
    border: thin solid #d9d9d9;
}

.certificate-l-border {
    border-left: thin solid #d9d9d9;
    padding-left: 18px;
}

.dashboard-video-list-card-link {
    color: black;
}

.certification-info-box {
    padding: 10px;
}
.certification-info-box .inner {
    min-height: 100px;
    padding: 8px;
}

.no-certificate-message {
    padding: 20px;
    text-align: center;
    font-size: 16px;
}

.certification-info-card {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.certificate-view-module-title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    background: white;
    padding: 0.5rem 2rem;
}

.certificate-view-module-title .count-span {
    font-size: 12px;
}

.certification-info-card .collection-info-card-text-container {
    background-color: white;
    padding: 2rem;
}

.certification-info-card .collection-info-card-title {
    font-size: 2rem;
    font-weight: bold;
}

.certification-info-card .certification-objectives {
    padding: 1rem;
    border: 1px solid #f0f2f5;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.certification-info-card .certification-objectives .objectives {
    column-count: 2;
    list-style-type: disclosure-closed;
}

.certification-info-card .certification-objectives .objectives li {
    padding: 0 10px;
}

.certification-info-card .certification-objectives .title {
    font-size: 1rem;
    text-transform: uppercase;
}

.certification-info-card .min-performance-level {
    margin-top: 1rem;
}

.certification-info-card .min-performance-level .ant-alert-info {
    background-color: #f5f5f5;
    border: 1px solid #c7c7c7;
}
.certification-info-card
    .min-performance-level
    .ant-alert-info
    .ant-alert-icon {
    color: #878787;
}

.certification-info-card .prerequisites-section {
    background-color: white;
    padding: 2rem;
    margin-top: 1rem;
}

.certification-info-card .prerequisites-section .title {
    font-size: 1rem;
    text-transform: uppercase;
}

/* -------- */

.certification-info-card .right-panel {
    height: auto;
}

.certification-info-card .right-panel .cupoints-container {
    display: flex;
    flex-direction: row;
    font-size: 1.7rem;
    font-weight: bold;
    padding: 1rem 0;
    line-height: 1.5rem;
    gap: 0.7rem;
}

.certification-info-card .right-panel .cupoints-container .cupoints-amount {
    font-size: 1rem;
    color: rgb(155 155 155 / 85%);
}

.certification-info-card .right-panel .ant-btn {
    height: 3rem;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bolder;
}

.certification-info-card .right-panel .ant-space-responsive > div {
    width: 100%;
}

.certification-info-card .right-panel .fixed-section {
    height: auto;
    background-color: white !important;
}

.certification-info-card .right-panel .image-section {
    position: relative;
    min-height: 330px;
}

.certification-info-card .right-panel .below-section {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    gap: 0.5rem;
}

.certification-info-card .right-panel .below-section .ant-alert-message {
    font-weight: bold;
}

.certification-info-card
    .right-panel
    .certificate-purchased-not-expired-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.certification-info-card .right-panel .duration {
    font-size: 13px;
}

/*----------- */
.dummy-certificate {
}
