@use "./variables";

.yt-marketing-player {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 12px;

    > div {
        width: 100%;
        overflow: hidden;
        aspect-ratio: 16/9;
        pointer-events: none;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 300%;
            margin-left: -100%;
        }
    }

    &.pausable {
        > div {
            pointer-events: all;
        }
    }
}
