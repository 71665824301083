@import "~antd/dist/antd.compact.less";

.play-icon-overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    opacity: 0;
    transition: all 0.2s ease-in;
    color: rgb(109, 172, 163);
}

.now-playing-icon-overlay {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-shadow: -1px -1px 0 rgb(148, 148, 148), 1px -1px 0 rgb(148, 148, 148),
        -1px 1px 0 rgb(148, 148, 148), 1px 1px 0 rgb(148, 148, 148);
}

.now-playing-video-card > .ant-card-cover {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.video-card.ant-card:hover > .ant-card-body > .play-icon-overlay {
    opacity: 1;
}

.video-card.ant-card > .ant-card-cover {
    transition: all 0.2s ease-in;
}

.video-card.ant-card:hover > .ant-card-cover {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.ant-list-item {
    background: transparent;
}
.video-card.ant-card {
    background: transparent;
    border-color: transparent;
    opacity: 0;
    transition: all 0.2s ease-in;
}

.video-card.ant-card:hover {
    background: #ffffff;
}

.video-card {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.video-card-options {
    margin: 10px 0px 0px 0px;
}

.collection-creator-modules-list {
    padding: 0;
}
.collection-creator-modules-list > li {
    list-style: none;
    margin: 0px 0px 10px 0px;
}

.collection-creator-new-module {
    padding: 0px 30px;
    width: calc(100% - 300px);
    height: 67vh;
}

.videos-in-module-list-container {
    // display: flex;
    // flex-direction: row;
    // flex-grow: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    // -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
}

.videos-in-module-list-container > ol {
    padding: 0;
}

.collection-creator-videos-in-module-list {
    overflow-x: scroll;
    padding: 10px 10px 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 238px;
    -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
}

.collection-creator-videos-in-module-list > li {
    list-style: none;
    margin: 0;
}

.new-module-container {
    display: flex;
}

.video-bank-container {
    height: 67vh;
    width: 300px;
    display: flex;
    flex-direction: column;
}

.video-bank-container > .video-bank-header {
    padding: 15px;
}

.video-bank-container > .ant-space-vertical > div:last-child {
    // margin-top: auto;
}

.video-bank {
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.video-bank-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px 10px 0px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
}
.video-bank-list > li {
    list-style: none;
    margin: 0px 0px 10px 0px;
}

.draggable-video {
    width: 280px;
    margin: 0px 10px 0px 0px;
    flex-shrink: 0;
}

.video-import {
    padding: 15px;
}

.video-import-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: 1px solid white;
    cursor: pointer;
}

.price-breakdown {
    display: flex;
    flex-direction: column;
}

.price-breakdown-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.price-breakdown-hint {
    cursor: pointer;
    transition: all 0.1s ease;
}

.price-breakdown-hint:hover {
    color: #018060 !important;
}

.borrowed-content-price-breakdown-modules {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}

.borrowed-content-price-breakdown-video {
    padding-left: 15px;
    margin: 5px 0px;
}

.account-type-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: 1px solid white;
    cursor: pointer;
    transition: all 0.1s ease;
}

.account-type-option:hover {
    border-color: #0aa679;
}

.collection-info-card-preview-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    max-height: 400px;
    height: 100%;
    padding: 25% 25px;
    background-color: rgb(82, 82, 82);
}

.collection-info-card-preview-image-loading {
    width: 100%;
    max-height: 400px;
    height: 100%;
    padding: 25% 25px;
    background-color: rgb(82, 82, 82);
}

.loading-module-list-module {
    width: 100%;
    height: 60px;
    background-color: rgb(39, 39, 39);
    border-radius: 2px;
}

.loading-module-list-module-video {
    width: 100%;
    background-color: rgb(39, 39, 39);
}

.loading-module-list-module-video-image {
    width: 100%;
    max-height: 400px;
    height: 100%;
    padding: 25% 25px;
    background-color: rgb(82, 82, 82);
}

.loading-module-list-module-video-title {
    padding: 5px 10px;
}

.breadcrumbs {
    margin: 0px 0px 10px 0px;
}

@primary-color: #0aa679;@badge-color: #722ed1;@layout-header-background: #f0f2f5;@btn-default-ghost-border: #E7E7E7;@btn-default-ghost-color: #000;@menu-dark-color: #000;