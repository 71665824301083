#chatbot-wrapper {
    margin-bottom: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#disclaimer {
    font-size: 9px;
    margin-top: 3px;
}

#message-list {
    height: calc(100vh - 375px);
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

/* #message-list.history-open {
    height: calc(100vh - 775px);
} */

#expand-on-history-list {
    height: 0;
    transition: height 0.25s ease-in;
    -o-transition: height 0.25s ease-in;
    -moz-transition: height 0.25s ease-in;
    -webkit-transition: height 0.25s ease-in;
}

#expand-on-history-list.visible {
    height: auto;
    border: thin solid lightgrey;
    padding: 5px 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    background: #f5f5f5;
    margin-top: auto;
}

.GPT-box {
    position: relative;
    background-color: #cfe6dbff;
    border-radius: 5px;
    padding: 7px;
    display: flex;
    margin-bottom: 9px;
    border-left: 6px solid darkgreen;
}

.GPT-icon {
    top: 0;
}

.user-box {
    background-color: lightgray;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 9px;

    border-left: 6px solid darkgray;

    display: flex;
}

#user-icon {
    padding-top: 2px;
}

.message-content {
    padding-left: 8px;
}

#stop-button {
    /* border: 2px solid pink;
    background-color: #f57d7d; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    background-color: whitesmoke;
    border: none;
    padding: 5px;
    border-radius: 5px;
}

#stop-button:hover {
    background-color: lightgray;
}

#wrapper {
    height: 100%;
}

.cugpt-wrapper {
    padding: 10px;
}

#chat-history {
    position: absolute;
    top: 0;
    height: 70%;
}

#box {
    /* position: "absolute";
    bottom: 0;
    flex: 1; */
    /* position: absolute; */
    padding-top: 10px;
    bottom: 0;
    /* width: auto; */
}

#message-box {
    border: 1px solid;
    border-color: lightgray;
    /* background-color:lightgray; */
    border-radius: 5px;
    height: 100px;
    display: flex;
    padding: 7px;
    flex: 1;
}

#message-box.disabled,
#message-box textarea:disabled {
    background-color: lightgray;
}

#message {
    resize: none;
    width: auto;
    overflow: auto;
    /* background-color: lightgray; */
    border: none;
    outline: none;
    flex: 6;
    font-size: 13.5px;
}

#submit-button {
    /* position: 'absolute';
    bottom: 5px;
    right: 0px; */
    display: block;
    margin-left: auto;
    flex: 1;
    background-color: lightgray;
    border: none;
    cursor: pointer;
}

#message-box.disabled #submit-button {
    cursor: default;
}

#buttons {
    display: flex;
    margin-bottom: 10px;
    gap: 12px;
}

#buttons div {
    flex-grow: 1;
    flex-basis: 0;
}

#buttons span,
#buttons button {
    width: 100%;
}

#summarize-button {
    flex: 1;
    margin-right: 20px;
    border-radius: 5px;
    background-color: #cfe6dc;
    border-color: #cfe6dcb3;
}

#summarize-button:hover {
    flex: 1;
    margin-right: 20px;
    background-color: #c6ded4;
}
#question-button {
    flex: 1;
    margin-right: 20px;
    background-color: #cfe6dc;
    border-radius: 5px;
    border-color: #cfe6dcb3;
}

#question-button:hover {
    flex: 1;
    margin-right: 20px;
    background-color: #c6ded4;
}

#expand-button {
    flex: 1;
    background-color: #cfe6dc;
    border-radius: 5px;
    border-color: #cfe6dcb3;
}

#expand-button:hover {
    flex: 1;
    background-color: #c6ded4;
}

#summary-clicked {
    background-color: #bbcec6;
    border-color: #bbcec6;
    flex: 1;
    margin-right: 20px;
    border-radius: 5px;
}

#question-clicked {
    background-color: #bbcec6;
    border-color: #bbcec6;
    flex: 1;
    margin-right: 20px;
    border-radius: 5px;
}

#expandOn-clicked {
    background-color: #bbcec6;
    border-color: #bbcec6;
    flex: 1;
    border-radius: 5px;
}

#alert {
    padding: 4px;
    background-color: #f5d7d7;
    margin-bottom: 7px;
    border-radius: 3px;
}

#stars {
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
}

#one,
#two,
#three,
#four,
#five {
    background-color: white;
    border: none;
}

.clicked {
    background-color: yellow;
}

#stars button {
    margin-right: -5px;
    width: 25px;
}

#feedback-button {
    margin-left: 5px;
    width: 10px;
    height: 14px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
}

.feedback {
    margin-bottom: 9px;
}

.star {
    margin-right: 1px;
}

.modal {
    position: absolute;
    z-index: 9999;
    top: 30%;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 15px;
    background-color: rgba(49, 177, 166, 0.97);
    border-radius: 20px;
    border-color: rgba(30, 112, 105, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.chat-comp {
    border-color: rgba(41, 155, 145, 0.9);
    top: 20%;
    left: 50%;
    width: 95%;
    height: 100;
}

#loading {
    display: flex;
    height: 20px;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 5px;
}

#response-message {
    white-space: pre-wrap;
}

.divider {
    border-top: 1px dotted #b3b3b3;
    margin: 0.5rem 0;
}

.mult-choice-option {
    background-color: lightgray;
    border: none;
    margin: 5px 0;
    border-radius: 6px;
    text-align: left;
    padding: 0.5rem;
    width: 100%;
}

.mult-choice-option.selected {
    background-color: darkgray;
}

.rating {
    width: 100%;
    display: flex;
    gap: 0.75rem;
    font-size: 1rem;
}

.rating button {
    display: block;
    margin-left: auto;
    flex: 1 1;
    background-color: transparent;
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
}

.like,
.dislike {
    display: inline-block;
    cursor: pointer;
}

.like {
    margin-left: auto;
}

.like:hover {
    color: #0aa679;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
}
.like.active {
    color: #0aa679;
}
.dislike:hover {
    color: #cf0b0b;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
}
.dislike.active {
    color: #cf0b0b;
}
.feedback-text {
    font-size: 12px;
    font-style: italic;
    color: #676767;
    font-weight: bold;
    line-height: 1.3;
    transition: all 0.2s ease-in-out;
}
.feedback-success-text {
    opacity: 0;
    font-size: 14px;
    font-style: italic;
    color: #0aa679;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
}
.feedback-success-text.show {
    opacity: 1;
}

.spinner {
    animation: spin infinite 1s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.explanation-section {
    background-color: gray;
    padding: 0.5rem;
    border-radius: 6px;
}

.explanation-section p {
    margin: 0;
}

.explanation-section.correct {
    background-color: #0aa67a30;
}
.explanation-section.incorrect {
    background-color: #ff4d5029;
}

.correct-text,
.wrong-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
}

.correct-text {
    color: #0aa679;
}

.wrong-text {
    color: #ff4d4f;
}

.expand-on-history-list-items .expand-on-history-list-item {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding: 5px;
}
.expand-on-history-list-items .header {
    display: flex;
    align-items: center;
    line-height: 1;
    cursor: pointer;
}
.expand-on-history-list-items .header button {
    margin-left: auto;
}
.expand-on-history-list-items h2 {
    font-size: 14px;
    padding: 10px 0px;
    margin: 0;
}
.expand-on-history-list-items.open {
    opacity: 1;
}
